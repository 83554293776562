// New colors
$blue-cadasta: #2e51a3;
$blue-dark: #0e305e;
$gray-med: #818EA3;

// Override Bootstrap
$body-bg: #f5f6f8;
$body-color: #595a5c;

$font-family-sans-serif: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

$headings-font-family: 'Roboto Condensed', 'Roboto', sans-serif;
$headings-color: $blue-dark;   

$link-color: #2a5db0;
