// Cadasta fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500|Roboto+Condensed");

// Cadasta colors
@import "styles/variables";

// Cadasta charts
@import "./views/dashboards/charts/charts";

// Bootstrap library
@import "~bootstrap/scss/bootstrap";
@import "~leaflet/dist/leaflet.css";

html,
body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase;
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  letter-spacing: -0.03rem;
}

.text-small,
.text-small > * {
  font-size: 0.875rem;
}

select:invalid,
input:invalid,
textarea:invalid {
  border-color: red;
}

.pointer {
  cursor: pointer;
}
