.chart {
  height: auto;
  min-width: 200px;
  min-height: 200px;
  > div {
    width: 100%;
    > svg {
      width: 100%;
      g {
        &.mark-rule line,
        &.mark-text text {
          opacity: 0.7 !important;
        }
      }
    }
  }
}

td.percentCol {
  width: 60px;
}

/* ------------------- KEY TABLES FOR CHARTS ------------------- */
.square {
  float: left;
  width: 14px;
  height: 14px;
  margin: 2px 8px 2px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  &.color-1 {
    background: #0e537e;
  }
  &.color-2 {
    background: #3db3e1;
  }
  &.color-3 {
    background: #69d1d9;
  }
  &.color-4 {
    background: #7ca721;
  }
  &.color-5 {
    background: #9dcd28;
  }
  &.color-6 {
    background: #8a143e;
  }
  &.color-7 {
    background: #ea3030;
  }
  &.color-8 {
    background: #e04b59;
  }
  &.color-9 {
    background: #e04b59;
  }
  &.color-10 {
    background: #fa7922;
  }
  &.gray {
    background: #fdb230;
  }
}
